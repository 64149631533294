import styled from "styled-components";
import React from "react";
import { useState, useEffect } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { checkedId, checkedCnt } from "../../recoil/atom";

export const OrderData = ({ data }) => {
  const [selectAll, setSelectAll] = useState(false);
  const selectedIdList = useRecoilValue(checkedId);
  const setSelectedIdList = useSetRecoilState(checkedId);
  const orderCnt = useRecoilValue(checkedCnt);
  const setOrderCnt = useSetRecoilState(checkedCnt);

  const header = [
    "주문번호",
    "id",
    "품목주문번호",
    "수령인",
    "수령인연락처",
    "주소",
    "상세주소",
    "배송메세지",
    "우편번호",
    "상품명",
    "수량",
    "수령일",
    "배송방식",
    "공동현관비밀번호",
    "배송회차",
    "식단포함여부",
    "prod_no",
    "수령요일",
  ];
  return (
    <TableFrame>
      <table
        style={{
          borderRadius: "12px",
          overflow: "hidden",
          borderCollapse: "collapse",
        }}
      >
        {header.map((hd, idx) => {
          const handleCheckedData = (event) => {
            const { checked } = event.target;
            setSelectAll(checked);
            setOrderCnt(data.length);
            if (checked) {
              data.flatMap((order) =>
                order.productList.map((product) =>
                  setSelectedIdList((prev) => {
                    const updatedSet = new Set(prev);
                    updatedSet.add(product.id);
                    return updatedSet;
                  })
                )
              );
            } else {
              setSelectedIdList(new Set());
              setOrderCnt(0);
            }
          };

          if (hd === "주문번호") {
            return (
              <TableHeader key={idx}>
                <div>
                  <CheckBox
                    type="checkbox"
                    onChange={handleCheckedData}
                    checked={selectAll}
                  ></CheckBox>
                  {hd}
                </div>
              </TableHeader>
            );
          } else {
            return <TableHeader key={idx}>{hd}</TableHeader>;
          }
        })}

        {data.map((order) => {
          const handleCheckedData = (event) => {
            const { checked } = event.target;

            if (checked) {
              order.productList.map((product) => {
                setSelectedIdList((prev) => {
                  const updatedSet = new Set(prev);
                  updatedSet.add(product.id);
                  return updatedSet;
                });
              });
              setOrderCnt((prev) => {
                return prev + 1;
              });
            } else {
              order.productList.map((product) => {
                setSelectedIdList((prev) => {
                  const updatedSet = new Set(prev);
                  updatedSet.delete(product.id);
                  return updatedSet;
                });
              });
              setOrderCnt((prev) => {
                return prev - 1;
              });
            }
          };

          return (
            <React.Fragment key={order.orderNo}>
              <tr>
                <OrderNoData rowSpan={order.productList.length + 1}>
                  <div>
                    <CheckBox
                      type="checkbox"
                      onChange={handleCheckedData}
                      checked={
                        selectedIdList.has(order.productList[0].id) || selectAll
                      }
                    ></CheckBox>
                    {order.orderNo}
                  </div>
                </OrderNoData>
              </tr>
              {order.productList.map((product) => (
                <tr key={product.id}>
                  <TableData>{product.id}</TableData>
                  <TableData>{product.prodOrderNo}</TableData>
                  <TableData>{product.name}</TableData>
                  <TableData>{product.phone}</TableData>
                  <TableData>{product.address}</TableData>
                  <TableData>{product.addressDetail}</TableData>
                  <TableData>{product.memo}</TableData>
                  <TableData>{product.postcode}</TableData>
                  <TableData>{product.prodName}</TableData>
                  <TableData>{product.count}</TableData>
                  <TableData>{order.receiveDate}</TableData>
                  <TableData>{product.deliveryWay}</TableData>
                  <TableData>{product.frontPw}</TableData>
                  <TableData>{product.delivRound}</TableData>
                  <TableData>{product.includedMeal ? 1 : 0}</TableData>
                  <TableData>{product.prodNo}</TableData>
                  <TableData>{product.receiveDays}</TableData>
                </tr>
              ))}
            </React.Fragment>
          );
        })}
      </table>
    </TableFrame>
  );
};

const TableFrame = styled.div`
  height: 500px;
  overflow: scroll;
  overflow-x: scroll;
  color: #414141;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 112.5% */
`;

const TableHeader = styled.th`
  background-color: #d2d2d2;
  white-space: nowrap;
  height: 47px;
  padding: 10px;
  vertical-align: middle;

  & > div {
    display: flex;
    align-items: center;
  }
`;

const TableData = styled.td`
  color: #414141;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  white-space: nowrap;
  border: 1px solid #f3f3f3;
  padding: 10px 23px 31px;
`;

const OrderNoData = styled.td`
  padding: 10px;
  background: #f3f3f3;
  color: #414141;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  border: 1px solid #d2d2d2;
  vertical-align: middle;

  & > div {
    display: flex;
    align-items: center;
  }
`;

const CheckBox = styled.input`
  accent-color: #00bb9d;
  border-radius: 2px;
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  margin-right: 20px;
`;
