import styled from "styled-components";
import alertcheck_ok from "../utils/icon/alertcheck-green.svg";
import alertcheck_bad from "../utils/icon/alertcheck-orange.svg";
import x_ok from "../utils/icon/x-green.svg";
import x_bad from "../utils/icon/x-orange.svg";
import { useSetRecoilState } from "recoil";
import { isOkAlert, isBadAlert } from "../recoil/atom";

export const OkAlert = () => {
  const setOkAlert = useSetRecoilState(isOkAlert);
  const closeAlert = () => {
    setOkAlert(false);
  };
  return (
    <AlertContainer>
      <OkAlertContent>
        <AlertFrame>
          <AlertTextFrame>
            <img src={alertcheck_ok}></img>
            변경이 완료되었습니다.
          </AlertTextFrame>
          <CloseBtn src={x_ok} onClick={closeAlert} />
        </AlertFrame>
      </OkAlertContent>
    </AlertContainer>
  );
};

export const BadAlert = () => {
  const setBadAlert = useSetRecoilState(isBadAlert);
  const closeAlert = () => {
    setBadAlert(false);
  };
  return (
    <AlertContainer>
      <BadAlertContent>
        <AlertFrame>
          <AlertTextFrame>
            <img src={alertcheck_bad}></img>
            변경이 완료되지 않았습니다.
          </AlertTextFrame>
          <CloseBtn src={x_bad} onClick={closeAlert} />
        </AlertFrame>
      </BadAlertContent>
    </AlertContainer>
  );
};

const AlertContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const OkAlertContent = styled.div`
  display: flex;
  width: 354px;
  padding: 16px;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  background: #e9fbf7;
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const AlertFrame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
`;

const BadAlertContent = styled.div`
  display: flex;
  width: 354px;
  padding: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: #fff3ef;
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const AlertTextFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;
const CloseBtn = styled.img`
  cursor: pointer;
`;
