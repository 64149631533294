import { useState, useEffect } from "react";
import styled from "styled-components";
import { OrderData } from "../components/orderData/OrderData";
import { OrderFilter } from "../components/OrderFilter";
import { HandleButton } from "../components/HandleButton";
import { RefreshDB } from "../components/RefreshDB";
import { refreshDB } from "../services/refreshDBService";
import { Loading } from "../components/Loading";
import { useRecoilValue } from "recoil";
import { isLoading } from "../recoil/atom";
import { useNavigate } from "react-router-dom";
import { getToken } from "../services/auth";

export const Home = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState([]);
  const loading = useRecoilValue(isLoading);

  useEffect(() => {
    if (!getToken("accessToken")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <HomePage>
      <Title>발주 관리</Title>
      <RefreshDB onClick={refreshDB}></RefreshDB>
      <OrderFilter setOrder={setOrder}></OrderFilter>
      <HandleButton></HandleButton>
      {order.length === 0 ? null : <OrderData data={order} />}
      {loading && <Loading />}
    </HomePage>
  );
};

const HomePage = styled.div`
  width: 100%;
  padding: 33px 44px;
  box-sizing: border-box;
`;

const Title = styled.header`
  color: #000;
  font-family: Pretendard;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 51.2px */
`;
