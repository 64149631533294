import client from "./client";

export const searchOrderForm = async (submitData) => {
  try {
    const response = await client.post("/admin/order", submitData);
    return response.data;
  } catch (error) {
    console.error(error.response.status);
    throw error;
  }
};
