import client from "./client";

export const adminLogin = async (userInfo) => {
  try {
    const response = await client.post("/login", userInfo);

    // 세션스토리지에 엑세스 토큰 저장
    sessionStorage.setItem("accessToken", response.data.accessToken);
    sessionStorage.setItem("loginId", userInfo.username);

    return response.data;
  } catch (error) {
    console.error(error.response);
    throw error;
  }
};

export const getToken = (key) => {
  return sessionStorage.getItem(key);
};
