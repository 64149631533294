import {
  ModalFrame,
  TitleFrame,
  Title,
  CloseBtn,
  DateFrame,
  LineFrame,
  PrevLabel,
  BorderLabel,
  UpdateFrame,
  UpdateLabel,
  UpdateBtn,
  Calendar,
} from "./ModalStyle";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  checkedId,
  checkedCnt,
  submitData,
  isLoading,
  isOkAlert,
  isBadAlert,
} from "../../recoil/atom";
import { updateReceiveDate } from "../../services/handleDataService";
import { useState } from "react";

export const UpdateReceiveDate = ({ setIsModalOpen }) => {
  const [newDate, setNewDate] = useState("");
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const selectedIdList = useRecoilValue(checkedId);
  const orderCnt = useRecoilValue(checkedCnt);
  const setselectedIdList = useSetRecoilState(checkedId);
  const setOrderCnt = useSetRecoilState(checkedCnt);
  const filteredData = useRecoilValue(submitData);
  const setLoading = useSetRecoilState(isLoading);
  const setOkAlert = useSetRecoilState(isOkAlert);
  const setBadAlert = useSetRecoilState(isBadAlert);

  const handleNewDate = (e) => {
    setNewDate(e.target.value);
  };

  const handleUpdate = async () => {
    const requestBody = {
      idList: [...selectedIdList],
      newDate: newDate,
    };

    try {
      setLoading(true);
      const response = await updateReceiveDate(requestBody);
      setselectedIdList(new Set());
      setOrderCnt(0);
      setIsModalOpen(false);
      setLoading(false);
      if (response.status === 200) {
        setOkAlert(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setBadAlert(true);
    }
  };

  return (
    <ModalFrame>
      <TitleFrame>
        <Title>수령일 변경</Title>
        <CloseBtn onClick={closeModal}></CloseBtn>
      </TitleFrame>
      <DateFrame>
        <LineFrame>
          <PrevLabel>기존 수령일</PrevLabel>
          <BorderLabel>{filteredData.sinceDate}</BorderLabel>
        </LineFrame>
        <LineFrame>
          <UpdateLabel>변경 수령일</UpdateLabel>
          <Calendar
            type="date"
            value={newDate}
            onChange={handleNewDate}
            required
          ></Calendar>
        </LineFrame>
      </DateFrame>
      <UpdateFrame>
        <LineFrame style={{ gap: "2px" }}>
          <PrevLabel>총 {orderCnt}개 배송건</PrevLabel>
          <BorderLabel>의 수령일을 변경합니다.</BorderLabel>
        </LineFrame>
        <UpdateBtn onClick={handleUpdate}>변경하기</UpdateBtn>
      </UpdateFrame>
    </ModalFrame>
  );
};
