import { useState } from "react";
import styled from "styled-components";
import { searchOrderForm } from "../services/orderFilterService";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { submitData, checkedId, checkedCnt, isLoading } from "../recoil/atom";

export const OrderFilter = ({ setOrder }) => {
  const [sinceDate, setSinceDate] = useState();
  const [untilDate, setUntilDate] = useState();
  const [searchOption, setSearchOption] = useState("주문번호");
  const [searchValue, setSearchValue] = useState("");
  const [delivWays, setDelivWays] = useState(["새벽배송", "택배배송"]);

  const setLoading = useSetRecoilState(isLoading);

  const setselectedIdList = useSetRecoilState(checkedId);
  const setOrderCnt = useSetRecoilState(checkedCnt);

  const setFilteredData = useSetRecoilState(submitData);
  const filteredData = useRecoilValue(submitData);

  const handleSinceDate = (e) => {
    setSinceDate(e.target.value);
    setUntilDate(e.target.value);
  };

  const handleUntilDate = (e) => {
    setUntilDate(e.target.value);
  };

  const handleSearchOption = (e) => {
    setSearchOption(e.target.value);
  };

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // 체크되었을 때
      setDelivWays((prevDelivWays) => [...prevDelivWays, value]);
    } else {
      // 체크 해제되었을 때
      setDelivWays((prevDelivWays) =>
        prevDelivWays.filter((way) => way !== value)
      );
    }
  };

  const createSubmitData = () => {
    const submitData = {
      sinceDate: sinceDate,
      untilDate: untilDate,
      option: searchOption,
      value: searchValue,
      delivWays: delivWays,
    };

    if (
      delivWays.length === 0 ||
      sinceDate === undefined ||
      untilDate === undefined
    ) {
      alert("배송 방법 및 수령일을 모두 선택해주세요.");
    } else {
      setFilteredData(submitData);
      return submitData;
    }
  };

  const handleSearch = async () => {
    try {
      setselectedIdList(new Set());
      setOrderCnt(0);
      setOrder([]);
      setLoading(true); // 로딩 상태를 true로 설정
      const res = await searchOrderForm(createSubmitData());
      setOrder(res);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false); // 에러 발생 시 로딩 상태를 false로 설정
    }
  };

  return (
    <>
      <ReceiveDate>
        <Calendar
          type="date"
          value={sinceDate}
          onChange={(e) => handleSinceDate(e)}
          required
        ></Calendar>
        <Label>~</Label>
        <Calendar
          type="date"
          value={untilDate}
          onChange={(e) => handleUntilDate(e)}
          required
        ></Calendar>
      </ReceiveDate>
      <SearchForm>
        <Select onChange={(e) => handleSearchOption(e)}>
          <Option>주문번호</Option>
          <Option>수령인</Option>
          <Option>수령인연락처</Option>
        </Select>
        <Form
          type="form"
          placeholder="검색어를 입력해주세요"
          onChange={(target) => handleSearchValue(target)}
        ></Form>
        <SearchBtn onClick={handleSearch}>조회</SearchBtn>
      </SearchForm>
      <DelivWays>
        <CheckBoxLabel>
          <CheckBox
            type="checkbox"
            value="새벽배송"
            onChange={handleCheckboxChange}
            checked={delivWays.includes("새벽배송")}
          />
          새벽배송
        </CheckBoxLabel>
        <CheckBoxLabel>
          <CheckBox
            type="checkbox"
            value="택배배송"
            onChange={handleCheckboxChange}
            checked={delivWays.includes("택배배송")}
          />
          택배배송
        </CheckBoxLabel>
      </DelivWays>
    </>
  );
};

const ReceiveDate = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
`;

const Calendar = styled.input`
  display: flex;
  width: 197px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 17px;
  border-radius: 6px;
  background: #f3f3f3;
  border-style: none;
`;

const Label = styled.a`
  margin: 0px 12px;
  color: #414141;
  text-align: right;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 38.4px */
`;

const Select = styled.select`
  display: inline-flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #d2d2d2;
  color: var(--grey-black-2, #000);
  text-align: left;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 22.4px */
`;

const Option = styled.option`
  border-radius: 6px;
  border: #d2d2d2;
  background: #fff;
`;

const Form = styled.input`
  width: 400px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid #d2d2d2;
  margin-left: 14px;
`;

const CheckBox = styled.input`
  accent-color: #00bb9d;
  border-radius: 2px;
  width: 22px;
  height: 22px;
  flex-shrink: 0;
`;

const CheckBoxLabel = styled.label`
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  display: flex;
  align-items: center;
`;

const DelivWays = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 48px;
`;

const SearchForm = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
`;

const SearchBtn = styled.button`
  display: flex;
  width: 149px;
  padding: 10px 41px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #000;
  color: #fff;
  text-align: right;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 22.4px */
  margin-left: 14px;
  cursor: pointer;
  border: 1px solid #000;

  &:hover {
    background: #00bb9d;
    border: #00bb9d;
  }
`;
