import styled from "styled-components";

export const Modal = ({ modalContent }) => {
  return <ModalBackground>{modalContent}</ModalBackground>;
};

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(141, 141, 141, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
