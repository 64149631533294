import styled from "styled-components";
import {
  Title,
  CloseBtn,
  UpdateLabel,
  LineFrame,
  BorderLabel,
  UpdateBtn,
  UpdateFrame,
} from "./ModalStyle";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  checkedId,
  checkedCnt,
  isLoading,
  isOkAlert,
  isBadAlert,
} from "../../recoil/atom";
import { postponeDelivPlan } from "../../services/handleDataService";

export const PostponeDelivPlan = ({ setIsModalOpen }) => {
  const selectedIdList = useRecoilValue(checkedId);
  const orderCnt = useRecoilValue(checkedCnt);
  const setselectedIdList = useSetRecoilState(checkedId);
  const setOrderCnt = useSetRecoilState(checkedCnt);
  const setLoading = useSetRecoilState(isLoading);
  const setOkAlert = useSetRecoilState(isOkAlert);
  const setBadAlert = useSetRecoilState(isBadAlert);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePostpone = async () => {
    const requestBody = {
      idList: [...selectedIdList],
    };

    try {
      setLoading(true);
      const response = await postponeDelivPlan(requestBody);
      setselectedIdList(new Set());
      setOrderCnt(0);
      setIsModalOpen(false);
      setLoading(false);
      if (response.status === 200) {
        setOkAlert(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setBadAlert(true);
    }
  };

  return (
    <ModalFrame>
      <TitleFrame>
        <Title>미루기</Title>
        <CloseBtn onClick={closeModal}></CloseBtn>
      </TitleFrame>
      <UpdateLabel>*수령일을 하루 단위로 조회 후, 적용해주세요.</UpdateLabel>
      <UpdateFrame>
        <LineFrame style={{ gap: "2px" }}>
          <UpdateLabel>총 {orderCnt}개 배송건</UpdateLabel>
          <BorderLabel>에 미루기를 적용합니다.</BorderLabel>
        </LineFrame>
        <UpdateBtn onClick={handlePostpone}>미루기</UpdateBtn>
      </UpdateFrame>
    </ModalFrame>
  );
};

const ModalFrame = styled.div`
  display: inline-flex;
  padding: 25px 18px;
  flex-direction: column;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  align-items: center;
  gap: 32px;
`;

const TitleFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 259px;
`;
