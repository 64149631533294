import axios from "axios";

// axios 인스턴스 생성
const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// 요청 인터셉터를 사용하여 모든 요청에 토큰 포함
client.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("accessToken");
    const loginId = sessionStorage.getItem("loginId");
    if (token && loginId) {
      config.headers.Authorization = `Bearer ${token}`;
      config.Origin = "https://sweetbalance-order.shop";
      if (config.data) {
        config.data.loginId = loginId;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      sessionStorage.removeItem("accessToken");
      sessionStorage.removeItem("loginId");
      window.location.replace("/");
    }
  }
);

export default client;
