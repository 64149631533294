import styled from "styled-components";
import {
  ModalFrame,
  Title,
  CloseBtn,
  TitleFrame,
  LineFrame,
  BorderLabel,
  UpdateLabel,
  UpdateBtn,
} from "./ModalStyle";
import download_black from "../../utils/icon/download_black.svg";
import { useSetRecoilState } from "recoil";
import { useState } from "react";
import { isLoading, isOkAlert, isBadAlert } from "../../recoil/atom";
import {read, utils} from "xlsx";
import setAndDiet_sample from "../../utils/excel/setAndDiet_sample.xlsx"
import { updateProduct } from "../../services/handleDataService";

export const NewProduct = ({ setIsModalOpen }) => {
  const setLoading = useSetRecoilState(isLoading);
  const setOkAlert = useSetRecoilState(isOkAlert);
  const setBadAlert = useSetRecoilState(isBadAlert);
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileName, setFileName] = useState("");
  const [data, setData] = useState(null);
  const keys = ["prod_no", "식단명", "배송횟수", "배송회차", "상품명", "변경 내용"];

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault(); 
    setIsDragOver(true);
  };

  const handleDragLeave = (event) => {
    setIsDragOver(false); 
  };
  
  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragOver(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      setFileName(file.name);
      readExcelFile(file);
    }
  };

  const handleFileImport = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name); 
      readExcelFile(file);
    }
  };

  const readExcelFile = (file) => {
    if (file && /\.(xlsx)$/i.test(file.name)) { 
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        json.map(row => {
          keys.map(key => {
            if(row[key] == null) {
              row[key] = ""; 
            }
          });
        });
        setData(json);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleSubmit = async () => {
    if(data && data.length !== 0){
      const requestBody = {data: data};
      try {
        setLoading(true);
        const response = await updateProduct(requestBody);
        setIsModalOpen(false);
        setLoading(false);
        if (response.status === 200) {
          setOkAlert(true);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
        setBadAlert(true);
      }
    }
    else{
      setData(null);
      setFileName("");
      alert("엑셀 파일을 확인해주세요.");
    }
  }

  return (
    <Frame>
      <TitleFrame>
        <Title>세트&식단 변경</Title>
        <CloseBtn onClick={closeModal}></CloseBtn>
      </TitleFrame>
      <NoticeBox>
        <NoticeLabel>
          *샘플파일을 다운로드 받아 입력 양식을 확인합니다.
        </NoticeLabel>
        <NoticeLabelDetail>
          추가: 모든 컬럼 기입 후 ‘변경 내용’ 열에 ‘추가’를 기입
        </NoticeLabelDetail>
        <NoticeLabelDetail>
          삭제: prod_no 기입 후 ‘변경 내용’ 열에 삭제를 기입.
        </NoticeLabelDetail>
        <NoticeLabelDetail>
          변경: 엑셀 상단에는 삭제할 내용, 하단에는 추가할 내용을 기입
        </NoticeLabelDetail>
      </NoticeBox>

      <LineFrame>
        <Label>양식 다운로드</Label>
        <ExcelDownload href={setAndDiet_sample} download="setAndDiet_sample">
          다운로드 <img src={download_black}></img>
        </ExcelDownload>
      </LineFrame>
      <LineFrame>
        <Label>파일 업로드</Label>
        <ExcelUploadBox onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
          <ExcelInput type="file" accept=".xlsx" className="file" onChange={handleFileImport}/>
          <ExcelUpload isDragOver={isDragOver}>
          {fileName.length === 0 ? `클릭하거나\n파일을 드래그해주세요` : `${fileName}`}
          </ExcelUpload>
        </ExcelUploadBox>
      </LineFrame>
      <LineFrame style={{ gap: "2px" }}>
        <BorderLabel>파일 업로드 후 아래 버튼을 클릭해주세요.</BorderLabel>
      </LineFrame>
      <UpdateBtn onClick={handleSubmit}>변경하기</UpdateBtn>
    </Frame>
  );
};

const Frame = styled(ModalFrame)`
  gap: 15px;
`;

const Label = styled(BorderLabel)`
  width: 100px;
  text-align: center;
`;

const NoticeBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  padding-left: 15px;
`;

const NoticeLabel = styled(UpdateLabel)`
  text-align: left;
`;

const NoticeLabelDetail = styled(UpdateLabel)`
  text-align: left;
  color: black;
  font-size: 13px;
  padding-left: 5px;
`;

const ExcelDownload = styled.a`
  display: flex;
  width: 209px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid #bdbdbd;
  background: #fff;
  color: #ff6838;
  font-size: 12px;
  gap: 5px;
`;

const ExcelUploadBox = styled.label`
  display: flex;
  width: 209px;
  height: 80px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 2px dashed #e2e6ea;
  background: #f3f3f3;
  color: #ff6838;
  font-size: 12px;
  &:hover {
    div {
      border: 1px dashed #ff6838;
    }
  }
`;

const ExcelUpload = styled.div`
  border-radius: 24px;
  border: 1px dashed ${props => props.isDragOver ? '#ff6838' : '#e2e6ea'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  text-overflow: clip;
  overflow: hidden;
  height: 40px;
  text-align: center;
  width: 200px;
  font-size: 12px;
  color: #868e96;
`;

const ExcelInput = styled.input`
  display: none;
`;

