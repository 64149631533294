import styled from "styled-components";
import {
  TitleFrame,
  Title,
  CloseBtn,
  BorderLabel,
  UpdateFrame,
  PrevLabel,
  UpdateBtn,
} from "./ModalStyle";
import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  checkedId,
  checkedCnt,
  isLoading,
  isOkAlert,
  isBadAlert,
} from "../../recoil/atom";
import { updateDelivInfo } from "../../services/handleDataService";

export const UpdateDelivInfo = ({ setIsModalOpen }) => {
  const selectedIdList = useRecoilValue(checkedId);
  const orderCnt = useRecoilValue(checkedCnt);
  const setselectedIdList = useSetRecoilState(checkedId);
  const setOrderCnt = useSetRecoilState(checkedCnt);
  const setLoading = useSetRecoilState(isLoading);
  const setOkAlert = useSetRecoilState(isOkAlert);
  const setBadAlert = useSetRecoilState(isBadAlert);

  const [address, setAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const [postcode, setPostcode] = useState("");
  const [frontPw, setFrontPw] = useState("");
  const [memo, setMemo] = useState("");
  const [deliveryWay, setDeliveryWay] = useState("새벽배송");

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleAddressDetail = (e) => {
    setAddressDetail(e.target.value);
  };

  const handlePostcode = (e) => {
    setPostcode(e.target.value);
  };

  const handleFrontPw = (e) => {
    setFrontPw(e.target.value);
  };

  const handleMemo = (e) => {
    setMemo(e.target.value);
  };

  const handleDeliveryWay = (e) => {
    setDeliveryWay(e.target.value);
  };

  const handleUpdate = async () => {
    const requestBody = {
      idList: [...selectedIdList],
      address: address,
      addressDetail: addressDetail,
      postcode: postcode,
      frontPw: frontPw,
      memo: memo,
      deliveryWay: deliveryWay,
    };

    try {
      setLoading(true);
      const response = await updateDelivInfo(requestBody);
      setselectedIdList(new Set());
      setOrderCnt(0);
      setIsModalOpen(false);
      setLoading(false);
      if (response.status === 200) {
        setOkAlert(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setBadAlert(true);
    }
  };

  return (
    <ModalFrame>
      <TitleFrame>
        <Title>배송 정보 변경</Title>
        <CloseBtn onClick={closeModal}></CloseBtn>
      </TitleFrame>
      <FormFrame>
        <LineFrame>
          <BorderLabel>주소</BorderLabel>
          <Form
            type="form"
            placeholder="변경하지 않을 칸은 비워주세요."
            onChange={handleAddress}
            value={address}
          ></Form>
        </LineFrame>
        <LineFrame>
          <BorderLabel>상세 주소</BorderLabel>
          <Form
            type="form"
            placeholder="변경하지 않을 칸은 비워주세요."
            onChange={handleAddressDetail}
            value={addressDetail}
          ></Form>
        </LineFrame>
        <LineFrame>
          <BorderLabel>우편번호</BorderLabel>
          <Form
            type="form"
            placeholder="변경하지 않을 칸은 비워주세요."
            onChange={handlePostcode}
            value={postcode}
          ></Form>
        </LineFrame>
        <LineFrame>
          <BorderLabel>공동현관 비밀번호 &nbsp;</BorderLabel>
          <Form
            type="form"
            placeholder="변경하지 않을 칸은 비워주세요."
            onChange={handleFrontPw}
            value={frontPw}
          ></Form>
        </LineFrame>
        <LineFrame>
          <BorderLabel>배송메세지</BorderLabel>
          <Form
            type="form"
            placeholder="변경하지 않을 칸은 비워주세요."
            onChange={handleMemo}
            value={memo}
          ></Form>
        </LineFrame>
        <LineFrame>
          <BorderLabel>배송방식</BorderLabel>
          <Select onChange={handleDeliveryWay}>
            <option>새벽배송</option>
            <option>택배배송</option>
          </Select>
        </LineFrame>
      </FormFrame>
      <UpdateFrame>
        <LineFrame style={{ gap: "2px", justifyContent: "center" }}>
          <PrevLabel>총 {orderCnt}개 배송건</PrevLabel>
          <BorderLabel>의 배송 정보를 변경합니다.</BorderLabel>
        </LineFrame>
        <UpdateBtn onClick={handleUpdate}>변경하기</UpdateBtn>
      </UpdateFrame>
    </ModalFrame>
  );
};

const ModalFrame = styled.div`
  display: inline-flex;
  padding: 25px 18px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
`;

const LineFrame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const FormFrame = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const Form = styled.input`
  display: flex;
  width: 250px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 17px;
  color: #414141;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  border-radius: 6px;
  background: #f3f3f3;
  border: none;
  box-sizing: border-box;
  overflow: hidden;
`;

const Select = styled.select`
  display: flex;
  padding: 10px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 76px;
  width: 250px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #d2d2d2;
  background: #fff;
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 22.4px */
`;
