import {
  ModalFrame,
  TitleFrame,
  Title,
  CloseBtn,
  UpdateLabel,
  LineFrame,
  BorderLabel,
  UpdateBtn,
  UpdateFrame,
} from "./ModalStyle";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  checkedId,
  checkedCnt,
  isLoading,
  isOkAlert,
  isBadAlert,
} from "../../recoil/atom";
import { cancelOrder } from "../../services/handleDataService";

export const CancelOrder = ({ setIsModalOpen }) => {
  const selectedIdList = useRecoilValue(checkedId);
  const orderCnt = useRecoilValue(checkedCnt);
  const setselectedIdList = useSetRecoilState(checkedId);
  const setOrderCnt = useSetRecoilState(checkedCnt);
  const setLoading = useSetRecoilState(isLoading);
  const setOkAlert = useSetRecoilState(isOkAlert);
  const setBadAlert = useSetRecoilState(isBadAlert);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCancel = async () => {
    const requestBody = {
      idList: [...selectedIdList],
    };

    try {
      setLoading(true);
      const response = await cancelOrder(requestBody);
      setselectedIdList(new Set());
      setOrderCnt(0);
      setIsModalOpen(false);
      setLoading(false);
      if (response.status === 200) {
        setOkAlert(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setBadAlert(true);
    }
  };

  return (
    <ModalFrame>
      <TitleFrame>
        <Title>배송 취소</Title>
        <CloseBtn onClick={closeModal}></CloseBtn>
      </TitleFrame>
      <UpdateFrame>
        <LineFrame style={{ gap: "2px" }}>
          <UpdateLabel>총 {orderCnt}개 배송건</UpdateLabel>
          <BorderLabel>의 배송을 취소합니다.</BorderLabel>
        </LineFrame>
        <UpdateBtn onClick={handleCancel}>취소하기</UpdateBtn>
      </UpdateFrame>
    </ModalFrame>
  );
};
