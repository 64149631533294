import styled from "styled-components";
import close from "../../utils/icon/x.svg";

export const ModalFrame = styled.div`
  display: inline-flex;
  padding: 25px 18px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.div`
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 32px */
  width: 100%;
`;

export const TitleFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 219px;
`;

export const CloseBtn = styled.img`
  content: url(${close});
  cursor: pointer;
  width: fit-content;
  height: fit-content;
`;

export const DateFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;
export const LineFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
`;
export const PrevLabel = styled.div`
  color: #919191;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 165%; /* 23.1px */
`;

export const BorderLabel = styled.div`
  color: #414141;
  text-align: right;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 22.4px */
`;

export const UpdateLabel = styled.div`
  color: #ff6838;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 165%; /* 23.1px */
`;

export const Calendar = styled.input`
  display: flex;
  width: 197px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 17px;
  border-radius: 6px;
  background: #f3f3f3;
  border-style: none;
`;

export const UpdateFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const UpdateBtn = styled.button`
  display: flex;
  width: 343px;
  padding: 14px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 24px */
  cursor: pointer;

  &:hover {
    background-color: #ff6838;
    border: 1px solid#ff6838;
  }
`;

export const SearchForm = styled.input`
  display: flex;
  width: 223px;
  padding: 10px;
  align-items: center;
  border-radius: 6px;
  border: none;
  background: #f3f3f3;
`;
