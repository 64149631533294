import { atom } from "recoil";

export const submitData = atom({
  key: "submitData",
  default: {
    sinceDate: "",
    untilDate: "",
    option: "",
    value: "",
    delivWays: "",
  },
});

export const checkedId = atom({
  key: "checkedId",
  default: new Set(),
});

export const checkedCnt = atom({
  key: "checkedCnt",
  default: 0,
});

export const isLoading = atom({
  key: "isLoading",
  default: false,
});

export const isOkAlert = atom({
  key: "isOkAlert",
  default: false,
});

export const isBadAlert = atom({
  key: "isBadAlert",
  default: false,
});
