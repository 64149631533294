import client from "./client";

export const cancelOrder = async (requestBody) => {
  try {
    const response = await client.patch("/admin/order", requestBody);
    return response;
  } catch (error) {
    console.error(error.response.data);
    throw error;
  }
};

export const updateReceiveDate = async (requestBody) => {
  try {
    const response = await client.put("/admin/order/receiveDate", requestBody);
    return response;
  } catch (error) {
    console.error(error.response);
    throw error;
  }
};

export const updateDelivInfo = async (requestBody) => {
  try {
    const response = await client.put("/admin/order/delivInfo", requestBody);
    return response;
  } catch (error) {
    console.error(error.response.data);
    throw error;
  }
};

export const replaceProduct = async (requestBody) => {
  try {
    const response = await client.put("/admin/product", requestBody);
    return response;
  } catch (error) {
    console.error(error.response);
    throw error;
  }
};

export const cancelProduct = async (requestBody) => {
  try {
    const response = await client.patch("/admin/product", requestBody);
    return response;
  } catch (error) {
    console.error(error.response);
    throw error;
  }
};

export const postponeDelivPlan = async (requestBody) => {
  try {
    const response = await client.put("/admin/order/delivPlan", requestBody);
    return response;
  } catch (error) {
    console.error(error.response);
    throw error;
  }
};

export const insertShipping = async (requestBody) => {
  try {
    const response = await client.post("/admin/shipping", requestBody);
    return response;
  } catch (error) {
    console.error(error.response);
    throw error;
  }
};

export const insertNewOrder = async (requestBody) => {
  try {
    const response = await client.post("/admin/newOrder", requestBody);
    return response;
  } catch (error) {
    console.error(error.response);
    throw error;
  }
};

export const updateProduct = async (requestBody) => {
  try {
    const response = await client.post("/admin/product", requestBody);
    return response;
  } catch (error) {
    console.error(error.response);
    throw error;
  }
};
