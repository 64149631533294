import styled from "styled-components";
import { refreshDB } from "../services/refreshDBService";
import refresh_grey from "../utils/icon/refresh_grey.svg";
import refresh_white from "../utils/icon/refresh_white.svg";
import refresh_green from "../utils/icon/refresh_green.svg";
import { isLoading } from "../recoil/atom";
import { useSetRecoilState } from "recoil";
export const RefreshDB = () => {
  const setLoading = useSetRecoilState(isLoading);

  const handleRefresh = async () => {
    try {
      setLoading(true);
      const response = await refreshDB();
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Frame>
      <RefrechBtn onClick={handleRefresh}>
        데이터 불러오기
        <img src={refresh_grey} />
      </RefrechBtn>
    </Frame>
  );
};

const Frame = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const RefrechBtn = styled.button`
  display: inline-flex;
  padding: 10px 15px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #414141;
  background: #f3f3f3;
  color: #414141;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
  margin-right: 12px;
  cursor: pointer;

  &:hover {
    & > img {
      content: url(${refresh_green});
    }
  }

  &:active {
    border-radius: 6px;
    border: 1px solid #d2d2d2;
    background: #00bb9d;
    color: #fff;
    & > img {
      content: url(${refresh_white});
    }
  }
`;
