import client from "./client";

export const getOrderData = async (submitData) => {
  try {
    const response = await client.post("/admin/excel", submitData, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error(error.response.data);
    throw error;
  }
};

export const convertToExcel = (data) => {
  const blob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "swbOrder.xlsx";
  a.click();
  URL.revokeObjectURL(url);
};

export const downloadExcelFile = async (submitData) => {
  try {
    const orderData = await getOrderData(submitData);
    convertToExcel(orderData);
  } catch (error) {
    console.error("엑셀 다운로드 에러", error);
  }
};
