import {
  ModalFrame,
  TitleFrame,
  Title,
  CloseBtn,
  LineFrame,
  BorderLabel,
  SearchForm,
  UpdateFrame,
  UpdateLabel,
  UpdateBtn,
  PrevLabel,
} from "./ModalStyle";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  checkedId,
  checkedCnt,
  isLoading,
  isOkAlert,
  isBadAlert,
} from "../../recoil/atom";
import { replaceProduct } from "../../services/handleDataService";
import { useState } from "react";

export const ReplaceProduct = ({ setIsModalOpen }) => {
  const selectedIdList = useRecoilValue(checkedId);
  const orderCnt = useRecoilValue(checkedCnt);
  const setselectedIdList = useSetRecoilState(checkedId);
  const setOrderCnt = useSetRecoilState(checkedCnt);
  const setLoading = useSetRecoilState(isLoading);
  const setOkAlert = useSetRecoilState(isOkAlert);
  const setBadAlert = useSetRecoilState(isBadAlert);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [curProdName, setCurProdName] = useState("");
  const [newProdName, setNewProdName] = useState("");

  const handleCurProdName = (e) => {
    setCurProdName(e.target.value);
  };

  const handleNewProdName = (e) => {
    setNewProdName(e.target.value);
  };

  const handleReplace = async () => {
    const requestBody = {
      idList: [...selectedIdList],
      curProdName: curProdName,
      newProdName: newProdName,
    };

    try {
      setLoading(true);
      const response = await replaceProduct(requestBody);
      setselectedIdList(new Set());
      setOrderCnt(0);
      setIsModalOpen(false);
      setLoading(false);
      if (response.status === 200) {
        setOkAlert(true);
      } 
    } catch (error) {
      console.error(error);
      setLoading(false);
      setBadAlert(true);
    }
  };

  return (
    <ModalFrame>
      <TitleFrame>
        <Title>대체출고 적용</Title>
        <CloseBtn onClick={closeModal}></CloseBtn>
      </TitleFrame>
      <LineFrame>
        <BorderLabel>기존 상품명</BorderLabel>
        <SearchForm
          placeholder="상품명을 입력하세요"
          onChange={handleCurProdName}
        ></SearchForm>
      </LineFrame>
      <LineFrame>
        <UpdateLabel>변경 상품명</UpdateLabel>
        <SearchForm
          placeholder="상품명을 입력하세요"
          onChange={handleNewProdName}
        ></SearchForm>
      </LineFrame>
      <UpdateFrame>
        <LineFrame style={{ gap: "2px" }}>
          <PrevLabel>총 {orderCnt}개 배송건</PrevLabel>
          <BorderLabel>의 상품을 변경합니다.</BorderLabel>
        </LineFrame>
        <UpdateBtn onClick={handleReplace}>변경하기</UpdateBtn>
      </UpdateFrame>
    </ModalFrame>
  );
};
