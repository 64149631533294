import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { RecoilRoot } from "recoil";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getToken } from "./services/auth";

function App() {
  return (
    <RecoilRoot>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/admin" element={<Home />} />
      </Routes>
    </RecoilRoot>
  );
}

export default App;
