import client from "./client";

export const refreshDB = async () => {
  try {
    const response = await client.get("/admin/database");
    return response.data;
  } catch (error) {
    console.error(error.response.data);
    throw error;
  }
};
