import {
  ModalFrame,
  Title,
  CloseBtn,
  TitleFrame,
  LineFrame,
  BorderLabel,
  SearchForm,
  UpdateFrame,
  PrevLabel,
  UpdateBtn,
} from "./ModalStyle";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  checkedCnt,
  checkedId,
  isLoading,
  isOkAlert,
  isBadAlert,
} from "../../recoil/atom";
import { useState } from "react";
import { insertShipping } from "../../services/handleDataService";

export const InsertShipping = ({ setIsModalOpen }) => {
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [prodName, setProdName] = useState("");
  const orderCnt = useRecoilValue(checkedCnt);
  const selectedIdList = useRecoilValue(checkedId);
  const setLoading = useSetRecoilState(isLoading);
  const setselectedIdList = useSetRecoilState(checkedId);
  const setOrderCnt = useSetRecoilState(checkedCnt);
  const setOkAlert = useSetRecoilState(isOkAlert);
  const setBadAlert = useSetRecoilState(isBadAlert);

  const handleProdName = (e) => {
    setProdName(e.target.value);
  };

  const handleInsert = async () => {
    const requestBody = {
      idList: [...selectedIdList],
      prodName: prodName,
    };

    try {
      setLoading(true);
      const response = await insertShipping(requestBody);
      setselectedIdList(new Set());
      setOrderCnt(0);
      setIsModalOpen(false);
      setLoading(false);
      if (response.status === 200) {
        setOkAlert(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setBadAlert(true);
    }
  };

  return (
    <ModalFrame>
      <TitleFrame>
        <Title>합배송 적용</Title>
        <CloseBtn onClick={closeModal}></CloseBtn>
      </TitleFrame>
      <LineFrame>
        <BorderLabel>추가할 상품명</BorderLabel>
        <SearchForm
          placeholder="상품명을 입력하세요"
          onChange={handleProdName}
        ></SearchForm>
      </LineFrame>
      <UpdateFrame>
        <LineFrame style={{ gap: "2px" }}>
          <PrevLabel>총 {orderCnt}개 배송건</PrevLabel>
          <BorderLabel>에 상품을 추가합니다.</BorderLabel>
        </LineFrame>
        <UpdateBtn onClick={handleInsert}>추가하기</UpdateBtn>
      </UpdateFrame>
    </ModalFrame>
  );
};
