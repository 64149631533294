import styled from "styled-components";
import { Oval } from "react-loader-spinner";

export const Loading = () => {
  return (
    <LoadingContainer>
      <Oval
        color="#FF6838"
        secondaryColor="#FFA723"
        heigth={80}
        width={80}
      ></Oval>
    </LoadingContainer>
  );
};

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(141, 141, 141, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
