import React from "react";
import styled from "styled-components";
import download from "../utils/icon/download.svg";
import { downloadExcelFile } from "../services/ExcelService";
import { useRecoilValue } from "recoil";
import { checkedId, submitData, isOkAlert, isBadAlert } from "../recoil/atom";
import { useState } from "react";
import { Modal } from "./Modal";
import { UpdateReceiveDate } from "./ModalContent/UpdateReceiveDate";
import { UpdateDelivInfo } from "./ModalContent/UpdateDelivInfo";
import { PostponeDelivPlan } from "./ModalContent/PostponeDelivPlan";
import { CancelOrder } from "./ModalContent/CancelOrder";
import { ReplaceProduct } from "./ModalContent/ReplaceProduct";
import { CancelProduct } from "./ModalContent/CancelProduct";
import { OkAlert, BadAlert } from "../components/HandleAlert";
import { NewOrder } from "./ModalContent/NewOrder";
import { NewProduct } from "./ModalContent/NewProduct";
import { InsertShipping } from "./ModalContent/InsertShipping";

export const HandleButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const okAlert = useRecoilValue(isOkAlert);
  const badAlert = useRecoilValue(isBadAlert);

  const openModal = (content) => {
    setModalContent(() =>
      React.cloneElement(content, {
        setIsModalOpen,
      })
    );
    setIsModalOpen(true); // 모달 열기
  };

  const filteredData = useRecoilValue(submitData);

  const handleDownload = () => {
    downloadExcelFile(filteredData)
      .then(() => {
        console.log("발주 파일 다운로드 완료");
      })
      .catch((error) => {
        console.log("발주 파일 다운로드 에러 발생");
      });
  };

  return (
    <BtnFrame>
      <BtnLeft>
        <UpdateBtn onClick={() => openModal(<UpdateReceiveDate />)}>
          수령일 변경
        </UpdateBtn>
        <UpdateBtn onClick={() => openModal(<UpdateDelivInfo />)}>
          배송 정보 변경
        </UpdateBtn>
        <DelivBtn onClick={() => openModal(<PostponeDelivPlan />)}>
          미루기
        </DelivBtn>
        <DelivBtn onClick={() => openModal(<CancelOrder />)}>
          배송 취소
        </DelivBtn>
        <ReplaceBtn onClick={() => openModal(<ReplaceProduct />)}>
          대체출고
        </ReplaceBtn>
        <ReplaceBtn onClick={() => openModal(<CancelProduct />)}>
          미출고
        </ReplaceBtn>
        <ReplaceBtn onClick={() => openModal(<InsertShipping />)}>
          합배송
        </ReplaceBtn>
        <ExcelBtn onClick={() => openModal(<NewOrder />)}>신규 주문</ExcelBtn>
        <ExcelBtn onClick={() => openModal(<NewProduct />)}>
          세트&식단 변경
        </ExcelBtn>
      </BtnLeft>
      <ExportFile onClick={handleDownload}>
        발주파일 내보내기 <img src={download}></img>
      </ExportFile>
      {isModalOpen && (
        <Modal
          setIsModalOpen={setIsModalOpen}
          modalContent={modalContent}
        ></Modal>
      )}
      {okAlert && <OkAlert />}
      {badAlert && <BadAlert />}
    </BtnFrame>
  );
};

const BtnFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const BtnLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  text-align: right;
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin-right: 12px;
  cursor: pointer;
`;

const UpdateBtn = styled(Button)`
  background: #000;
  border: 1px solid #000;
  color: #fff;
`;

const DelivBtn = styled(Button)`
  background: #f3f3f3;
  border: 1px solid #f3f3f3;
  color: #000;
  font-weight: 400;
`;

const ReplaceBtn = styled(Button)`
  border: 1px solid #414141;
  font-weight: 400;
  color: #414141;
  background: #f3f3f3;
`;

const ExportFile = styled(Button)`
  background: #000;
  border: 1px solid #000;
  color: #fff;
`;

const ExcelBtn = styled(Button)`
  color: #00bb9d;
  background: #fff;
  border: 1px solid #00bb9d;
`;
