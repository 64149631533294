import styled from "styled-components";
import { useState, useEffect } from "react";
import { adminLogin } from "../services/auth";
import { getToken } from "../services/auth";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (getToken("accessToken")) {
      navigate("/admin");
    }
  }, [navigate]);

  const handleId = (e) => {
    setId(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async () => {
    const submitData = {
      username: id,
      password: password,
    };
    await adminLogin(submitData);
    navigate("/admin");
  };

  return (
    <LoginPage>
      <Content>
        <Title>로그인</Title>
        <Label>아이디</Label>
        <Form
          type="form"
          placeholder="아이디를 입력해주세요."
          onChange={handleId}
        ></Form>
        <Label>비밀번호</Label>
        <Form
          type="password"
          placeholder="비밀번호를 입력해주세요."
          onChange={handlePassword}
        ></Form>
        <LoginBtn type="submit" onClick={handleSubmit}>
          로그인
        </LoginBtn>
      </Content>
    </LoginPage>
  );
};

const LoginPage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: 350px;
  height: 372.5px;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  /* flex-shrink: 0; */
`;

const Title = styled.div`
  color: #000;
  text-align: center;
  font-family: Pretendard;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 51.2px */
  margin-bottom: 31px;
`;

const Label = styled.div`
  color: #414141;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
`;

const Form = styled.input`
  display: flex;
  width: 350px;
  padding: 13px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border: 1px solid #e5e8eb;
  background: #fff;
  color: var(--grey-grey-3, #bdbdbd);
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 24px */
  margin-bottom: 24px;
  box-sizing: border-box;
`;

const LoginBtn = styled.button`
  display: flex;
  width: 350px;
  padding: 14px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #000000;
  border-color: #000000;
  color: #fff;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 24px */
  box-sizing: border-box;
  margin-top: 24px;
  border-style: none;
  cursor: pointer;

  &:hover {
    background: #00bb9d;
    border-color: #00bb9d;
  }
`;
